import React from 'react';
import'./teams-page.css';

function Technical() {
  return (
    <div>
      <h1>Tech team</h1>
    </div>
  )
}

export default Technical;
